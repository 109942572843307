import { graphql } from "gatsby";
import Image from "gatsby-image";
import { RichText } from "prismic-reactjs";
import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Layout from "../components/Layout";

export const query = graphql`
  query GalleryQuery($uid: String!, $lang: String!) {
    prismic {
      gallery(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
        }
        name
        pictures {
          picture
          pictureSharp {
            id
            picture: childImageSharp {
              main: fluid {
                src
                srcSet
                aspectRatio
                sizes
              }
              thumbnail: fluid(
                maxWidth: 400
                maxHeight: 400
                cropFocus: CENTER
              ) {
                src
                aspectRatio
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

const Gallery = (props: any) => {
  const [modalState, setModalState] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openModal = (index: number) => {
    setImageIndex(index);
    setModalState(true);
  };

  const gallery = props.data.prismic.gallery;

  if (!gallery) return null;

  return (
    <Layout title={RichText.asText(gallery.name)} lang={gallery._meta.lang}>
      <RichText render={gallery.name} />

      <div className="flex flex-wrap">
        {gallery.pictures.map((picture: any, index: number) => (
          <div
            key={`image-${index}`}
            className="cursor-pointer w-full md:w-1/3 lg:w-1/3 xl:w-1/4 h-auto p-1"
            onClick={() => openModal(index)}
            onKeyPress={() => openModal(index)}
            role="link"
            tabIndex={0}
          >
            <Image fluid={picture.pictureSharp.picture.thumbnail} />
          </div>
        ))}
      </div>

      <ModalGateway>
        {modalState ? (
          <Modal
            onClose={() => setModalState(false)}
            styles={{
              blanket: (base) => ({
                ...base,
                zIndex: 100,
              }),
              positioner: (base) => ({
                ...base,
                zIndex: 100,
              }),
            }}
          >
            <Carousel
              views={gallery.pictures.map(
                (picture: any) => picture.pictureSharp.picture.main
              )}
              currentIndex={imageIndex}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};

export default Gallery;
